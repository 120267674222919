.accordion {
    margin: 20px;
  }
  
  .accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .accordion-header {
    background-color: #f7f7f7;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .accordion-header.active {
    background-color: #e0e0e0;
  }
  
  .guia-unal-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
  }
  
  .guia-unal-form label {
    grid-column: span 1;
  }
  
  .guia-unal-form input,
  .guia-unal-form textarea {
    grid-column: span 1;
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .guia-unal-form button {
    grid-column: span 2;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .guia-unal-form button:hover {
    background-color: #0056b3;
  }
  
  .message {
    grid-column: span 2;
    color: green;
    font-weight: bold;
  }
  
  .button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }